/* Header */

header {
    position: fixed;
    z-index: 99;
    width: 100%;
    font-family: "oswald", "gilory", sans-serif;
    color: var(--primary);
    font-size: var(--font-s);
    height: calc(var(--header-height)*1.4);
    transition: var(--transalte);
}
header:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--light);
    transform: translateX(100%);
    transition: var(--transalte);
}
.fix.down header {
    height: calc(var(--header-height)*0.8);
    transition: var(--transalte);
}
.fix header:before{
    transform: translateX(0%);
    transition: var(--transalte);
}
header .container{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}
.header-logo {
    width: calc(140px + 50 * (100vw - 320px) / 1080);
    z-index: 2000;
    height: 100%;
    background-image: url("../../../public/img/logo_black.png");
    background-size: contain;
    background-repeat: no-repeat;
}
header .contact {
    font-family: var(--ff2);
    padding-top: 15px;
    text-align: end;
    transition: var(--transalte);
}
header .contact > * + * {
    margin-left: 15px;
}
.fix.down header .contact {
    padding-top: 0;
    height: 0;
    opacity: 0;
    transition: var(--transalte);
}

/* header homepage */

header.homepage {
    color: var(--light);
}
header.homepage .header-logo {
    background-image: url("../../../public/img/logo_white.png");
}
.fix header.homepage,
.nav-open header.homepage {
    color: var(--primary);
}
.fix header.homepage .header-logo,
.nav-open header.homepage .header-logo {
    background-image: url("../../../public/img/logo_black.png");
}

header .nav-list li a {
    text-decoration: none;
    text-transform: uppercase;
}


/* bnv */
.btn-nav {
    display: none;
    align-items: center;
    --bar_w: 20px;
    --bar_h: 2px;
    --bar_s: 6px;
    --bar-hover: #aaa;
    --bar-color: var(--primary);
    height: calc(var(--bar_w) * 2);
    width: calc(var(--bar_w) * 2);
    justify-content: center;
    cursor: pointer;
}
header.homepage .btn-nav {
    --bar-color: var(--light);
}
.fix header.homepage .btn-nav,
.nav-open header.homepage .btn-nav {
    --bar-color: var(--primary);
}
.hbg {
    width: var(--bar_w);
    height: calc(var(--bar_h) + var(--bar_s) * 2);
}
.hbg span,
.hbg span:before,
.hbg span:after {
    width: var(--bar_w);
    height: var(--bar_h);
    display: block;
    background: var(--bar-color);
}
.hbg span {
    transform: translateY(var(--bar_s));
    border-radius: 5px;
}
.hbg span:before,
.hbg span:after {
    content: '';
    position: absolute;
    left: 0;
    background: var(--bar-color);
}
.hbg span:before {
    top: var(--bar_s);
    transition: .3s ease-in-out;
}
.hbg span:after {
    bottom: var(--bar_s);
    transition: .3s ease-in-out;
}
.nav-open .hbg span,
.nav-open .hbg:hover span {
    background: transparent;
}
.nav-open .hbg span:before {
    top: 0;
    transform: rotate(45deg);
    transition: .3s ease-in-out;
    background: var(--bar-color);
}
.nav-open .hbg span:after {
    bottom: 0;
    transform: rotate(-45deg);
    transition: .3s ease-in-out;
    background: var(--bar-color);
}
.btn-nav:hover .hbg span,
.btn-nav:hover .hbg span:before,
.btn-nav:hover .hbg span:after {
    background: var(--bar-hover);
}

@media screen and (min-width: 1050px) {
    .nav-wrapper {
        height: 100%;
    }
    .nav-list {
        --margin: 15px;
        display: flex;
        letter-spacing: 0.8px;
        margin: 0 calc(-1 * var(--margin));
        height: 100%;
        align-items: center;
    }
    .nav-list li {
        margin: 0 var(--margin);
    }
}

@media screen and (max-width: 1050px) {
    .btn-nav {
        margin: auto 0;
        display: flex;
        z-index: 2000;
    }
    .nav-main {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        z-index: 1000;
        opacity: 0;
        pointer-events: none;
        transition: .3s var(--cubic);
    }
    .nav-wrapper {
        background-color: #fff;
        width: 100%;
        height: 100%;
        z-index: 1500;
        padding: var(--header-height) var(--container-padding) 0 var(--container-padding);
        display: flex;
        align-items: center;
        flex-direction: column;
        transform: translateY(-50%);
        transition: .5s var(--cubic);
    }
    .nav-list {
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .nav-list a {
        font-size: 20px;
        display: flex;
        height: 70px;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-bottom: 1px solid #ddd;
    }
    .nav-overlay {
        background-color: rgba(0, 0, 0, .1);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
    .nav-open .nav-main {
        opacity: 1;
        pointer-events: auto;
    }
    .nav-open .nav-wrapper {
        transform: translateY(0);
    }
}